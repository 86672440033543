import React, { useEffect } from "react";
import styled from "styled-components";
import FadeInCarousel from "../../components/carousel/FadeInCarousel";
import LandingBG from "../../assets/caraousel1.png";
import LandingBG2 from "../../assets/caraousel2.png";
import LandingBG3 from "../../assets/carousel3.jpg";
import { Link } from "react-router-dom";
import Aos from "aos";

const HeroSection = () => {
  const images = [LandingBG, LandingBG2, LandingBG3];
  useEffect(() => {
    Aos.init({
      offset: 10,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);
  return (
    <TheHero>
      <div className="hero-content">
        <div className="hero-writeup">
          <div className="headingdiv">
            <p data-aos="fade-up" data-aos-delay="400">
              Explore New Experience With
            </p>
             {/* Developed by Emerie Obinna Eze */}
          </div>
          <div className="header-div">
            <h1 data-aos="zoom-in" data-aos-delay="600">
              Creme Suites & Hotel
            </h1>
          </div>
          <div className="abt-div">
            <Link
              to="/rooms"
              className="primary-link"
              data-aos="fade-up"
              data-aos-delay="800"
            >
              Explore
            </Link>
          </div>
        </div>
        <div className="overlay"></div>
        <FadeInCarousel images={images} interval={5000} />
      </div>
    </TheHero>
  );
};

const TheHero = styled.div`
  width: 100%;
  height: fit-content;
  padding: 1rem 1rem;
  background-color: var(--bg-body);
  .headingdiv {
    width: fit-content;
    height: 3rem;
    overflow: hidden;
  }
  .abt-div {
    width: fit-content;
    height: 4rem;
    padding-top:1rem;
    overflow: hidden;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 3;
  }
  .hero-content {
    position: relative;
    width: 100%;
    height: 97vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    .hero-writeup {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      /* gap:1.5rem; */
      z-index: 4;
      p {
        font-family: "Jost", sans-serif;
        font-size: 1.5rem;
        color: white;
        margin-bottom: -1.5rem;
        letter-spacing: 0.2rem;
      }
      h1 {
        font-size: 8rem;
        font-family: "Allison", system-ui;
        color: white;
        font-weight: 500;
        text-align: center;
      }
    }
  }
  @media screen and (max-width: 1200px) {
  }
  @media screen and (max-width: 900px) {
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    height: fit-content;
    padding: 0rem;
    background-color: var(--bg-body);
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
      z-index: 3;
    }
    .hero-content {
      position: relative;
      width: 100%;
      height: 97vh;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0rem;
      .hero-writeup {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: 4;
        p {
          font-family: "Jost", sans-serif;
          font-size: 1rem;
          color: white;
          margin-bottom: -0.5rem;
          letter-spacing: 0.2rem;
        }
        h1 {
          font-size: 3.5rem;
          font-family: "Allison", system-ui;
          color: white;
          font-weight: 400;
        }
      }
    }
  }
  @media screen and (max-width: 420px) {
    width: 100%;
    height: fit-content;
    padding: 0rem;
    background-color: var(--bg-body);
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
      z-index: 3;
    }
    .hero-content {
      position: relative;
      width: 100%;
      height: 97vh;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0rem;
      .hero-writeup {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: 4;
        p {
          font-family: "Jost", sans-serif;
          font-size: 1rem;
          color: white;
          margin-bottom: -0.5rem;
          letter-spacing: 0.2rem;
        }
        h1 {
          font-size: 3.5rem;
          font-family: "Allison", system-ui;
          color: white;
          font-weight: 400;
        }
      }
    }
  }
  @media screen and (max-width: 350px) {
  }
`;
export default HeroSection;
